/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Shreethemes
   E-mail: shreethemes@gmail.com
   Version: 1.0.0
   Created: August 2019
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 ================================*/

@import "variables";
@import "bootstrap-custom";
@import "components";
@import "general";
@import "helper";
@import "menu";
@import "home";
@import "features";
@import "testi";
@import "price";
@import "cta";
@import "blog";
@import "work";
@import "team";
@import "user";
@import "countdown";
@import "contact";
@import "footer";

.Typist {
	display: inline;
}

li.active > a {
	color: #2f55d4 !important
}

.main-slider > .slides {
	margin-left: 0 !important;
	padding-left: 0 !important;
}

.nav-link {
	border-radius: 10px !important;
}

.pagination .active a {
	color: #ffffff !important;
}

a {
	&:hover {
		cursor: pointer;
	}
}

.back-to-top {
	line-height: 31px !important;
}

.progress-bar {
	background-color: #2f55d4;
}

#mt-1 {
	margin-top: 5px !important;
}

#mb-1 {
	margin-top: 5px !important;
}

nav.navbar {
    position: fixed;
    z-index: 999;
    margin: 10px;
    width: calc( 100% - 20px );
    border-radius: 4px;
    background-color: transparentize( #f8f9fc, .2 ) !important;
	box-shadow: $primary;
	
	.navbar-brand {
		
		h6 {
			margin-bottom: 0;
		}
	}
	
	.navbar-toggler {
		border: 0;
	}
}

.bg-page {
	height: 40vh;
	@include home-common();
	background-repeat: no-repeat !important;
}
