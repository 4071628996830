//
// variables.scss
//

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
// Color
$white:                     #ffffff;

$primary:                   #4CAF50;
$secondary:                 #388E3C;
$success:                   #2eca8b;
$warning:                   #f17425;
$info:                      #17a2b8;
$danger:                    #e43f52;
$dark:                      darken( $secondary, 0.5 );
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
// Overlay
$overlay:                   rgba(35, 37, 47, 0.7);
$gradident-overlay:         rgba($primary, 0.55);
// Body Background
$body-color:                #161c2d;
$shadow:                    0 0 3px rgba(0,0,0,.15);
// Base font
$font-size-base:            16px;

$font-family-base:          'Nunito', sans-serif;
$font-family-secondary:     'Nunito', sans-serif;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
)
